<template>
  <b-card>
    <header-table title="المخاتير" @create="onAdd" />
    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="4">
          <form-input v-model="filter" placeholder=" ابحث هنا عن المنطقة التي يغطيها..." />
        </b-col>
      </b-row>
    </div>
    <data-table ref="estatesTableee" :fields="fields" :filter="filter" ep="/mayors">
      <template #cell(actions)="row">
        <div class="d-flex justify-content-end ">
          <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)" />
        </div>
      </template>
    </data-table>
    <form-modal ref="estateModalAboutContent" :formSchema="estatesFormSchema" title="إضافة" v-model="activModel"
      :loading="loading" @confirm="onModalConfirmed">
    </form-modal>
  </b-card>
</template>
  
<script>
import { BCard, BButton, BCollapse, BRow, BCol } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/data-table/index.vue"
import FormModal from "@/components/FormModal.vue"
import HeaderTable from "@/components/data-table/components/header-table.vue"
import ActionsTable from "@/components/data-table/components/actions-table.vue"
import FormInput from "@/components/form-input/index.vue"

export default {
  components: { BRow, BCol, BCard, BButton, BCollapse, DataTable, FormModal, HeaderTable, ActionsTable, FormInput },
  data() {
    return {
      activModel: false,
      filter: "",
      fields: [
        { key: "name", label: "اسم المختار" },
        { key: "address", label: "العنوان" },
        { key: "responsible_area", label: "المنطقة التي يغطيها" },
        { key: "phone_number", label: "الرقم" },
        { key: "telephone", label: "الرقم الهاتف" },
        { key: "actions", label: "" }
      ],

      estatesFormSchema: {
        name: {
          component: "FormInput",
          key: "name",
          attrs: {
            id: "name",
            label: "اسم المختار",
            rules: "required",
          },
        },
        address: {
          component: "FormInput",
          key: "address",
          attrs: {
            id: "address",
            label: "العنوان",
            rules: "required",
          },
        },
        responsible_area: {
          component: "FormInput",
          key: "responsible_area",
          attrs: {
            id: "responsible_area",
            label: "المنطقة التي يغطيها",
            rules: "required",
          },
        },

        phone_number: {
          component: "FormMask",
          key: "phone_number",
          attrs: {
            options: "phone",
            rules: "required|min:10",
            label: "رقم الموبايل",
            dir: "ltr",
          },
        },
        telephone: {
          component: "FormMask",
          key: "telephone",
          attrs: {
            options: "phone",
            id: "telephone",
            label: "الرقم الهاتف",
            rules: "required",
          },
        },
      }
    };
  },
  methods: {
    ...mapActions("manageCenter/mayors", ["update", "delete", "create"]),
    onAdd() {
      this.$refs.estateModalAboutContent.init({});
      this.activModel = true;
    },
    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف", {
          title: "تأكيد حذف",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({ id: item.id }).then(() => {
              this.$refs.estatesTableee.refreshTable();
            });
        });
    },
    onUpdateClicked(form) {
      this.$refs.estateModalAboutContent.init(form);
      this.activModel = true;
    },
    onModalConfirmed(form) {
      if (form.id) {
        this.update({ data: form, id: form.id }).then((res) => {
          if (res.status >= 200 && res.status < 300) {
            this.$refs.estatesTableee.refreshTable();
            this.activModel = false;
          }

        });
      } else
        this.create({ data: form }).then((res) => {
          if (res.status >= 200 && res.status < 300) {
            this.$refs.estatesTableee.refreshTable();
            this.activModel = false;
          }
        });
    },

  },

  computed: {
    ...mapGetters("manageCenter/mayors", ["loading"]),
  },
};
</script>